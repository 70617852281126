import { Subject, ReplaySubject } from 'rxjs';
import { bindSagas, all, allOnce, raceOnce, sequence, sequenceOnce, runSaga } from './saga';
import * as effects from 'redux-saga/effects';

function manageNamespace(name) {
    let parts = name.split('.');
    const obj = {
        namespace: 'generic',
        eventname: name
    };
    if(parts.length > 1) {
        obj.eventname = parts.pop();
        obj.namespace = parts.splice(-1).join('.');
    }
    return obj;
}

function removeHandler(handler, observers) {
    for (let i = 0;i < observers.length;i++) {
        if (observers[i].destination._next === handler) {
            observers[i].unsubscribe();
            return;
        }
    }
}

function reportEvent(type, { eventname, namespace }, data) {
    if (typeof window !== 'undefined' && window.location.search.indexOf('showEvents=true') !== -1) {
        console.log('LIFE_CYCLE_EVENT TRIGGERED: ', {type, eventname, namespace, data});
    }
}

const builder = {
    on(context, namespaceObj, subjectFn, handler) {
        context.events[namespaceObj.namespace] = context.events[namespaceObj.namespace] || {};
        if (!context.events[namespaceObj.namespace][namespaceObj.eventname]) {
            context.events[namespaceObj.namespace][namespaceObj.eventname] = {};
            context.events[namespaceObj.namespace][namespaceObj.eventname].subject = new subjectFn();
        }
        context.events[namespaceObj.namespace][namespaceObj.eventname].subject.subscribe(handler);
    },
    off(context, namespaceObj, handler) {
        if (context.events[namespaceObj.namespace] && context.events[namespaceObj.namespace][namespaceObj.eventname]) {
            if (handler) {
                removeHandler(handler, context.events[namespaceObj.namespace][namespaceObj.eventname].subject.observers);
            } else {
                context.events[namespaceObj.namespace][namespaceObj.eventname].subject.unsubscribe();
                delete context.events[namespaceObj.namespace][namespaceObj.eventname];
            }
        }
    },
    once(context, namespaceObj, subjectFn, handler) {
        context.events[namespaceObj.namespace] = context.events[namespaceObj.namespace] || {};
        if (!context.events[namespaceObj.namespace][namespaceObj.eventname]) {
            context.events[namespaceObj.namespace][namespaceObj.eventname] = {};
            context.events[namespaceObj.namespace][namespaceObj.eventname].subject = new subjectFn();
            context.events[namespaceObj.namespace][namespaceObj.eventname].once = true;
        }
        context.events[namespaceObj.namespace][namespaceObj.eventname].subject.subscribe(handler);
    },
    trigger(context, namespaceObj, subjectFn, data) {
        context.events[namespaceObj.namespace] = context.events[namespaceObj.namespace] || {};
        if (!context.events[namespaceObj.namespace][namespaceObj.eventname]) {
            context.events[namespaceObj.namespace][namespaceObj.eventname] = {};
            context.events[namespaceObj.namespace][namespaceObj.eventname].subject = new subjectFn();
        }
        let response = {
            eventName: namespaceObj.eventname,
            eventNamespace: namespaceObj.namespace,
            output: data
        };
        context.events[namespaceObj.namespace][namespaceObj.eventname].subject.next(response);
        if (context.events[namespaceObj.namespace]['*']) {
            context.events[namespaceObj.namespace]['*'].subject.next(response);
        } else if (namespaceObj.namespace !== 'generic') {
            if (context.events.generic['*']) {
                context.events.generic['*'].subject.next(response);
            }
        }
        if (context.events[namespaceObj.namespace] &&
            context.events[namespaceObj.namespace][namespaceObj.eventname] &&
            context.events[namespaceObj.namespace][namespaceObj.eventname].once) {
            this.off(context, namespaceObj);
        }
    }
};

const LIFE_CYCLE_EVENT_BUS = {
    events: {},
    effects,
    all,
    allOnce,
    raceOnce,
    sequence,
    sequenceOnce,
    runEventSequence: runSaga,
    on(name, handler) {
        const namespaceObj = manageNamespace(name);
        builder.on(this, namespaceObj, Subject, handler);
    },
    off(name, handler) {
        const namespaceObj = manageNamespace(name);
        builder.off(this, namespaceObj, handler);
    },
    once(name, handler) {
        const namespaceObj = manageNamespace(name);
        builder.once(this, namespaceObj, Subject, handler);
    },
    trigger(name, data) {
        const namespaceObj = manageNamespace(name);
        builder.trigger(this, namespaceObj, Subject, data);
        reportEvent('Event', namespaceObj, data);
    },
    lifeCycle: {
        events: {},
        on(name, handler) {
            const namespaceObj = manageNamespace(name);
            builder.on(this, namespaceObj, ReplaySubject, handler);
        },
        off(name, handler) {
            const namespaceObj = manageNamespace(name);
            builder.off(this, namespaceObj, handler);
        },
        trigger(name, data) {
            const namespaceObj = manageNamespace(name);
            builder.trigger(this, namespaceObj, ReplaySubject, data);
            reportEvent('LifeCycle Event', namespaceObj, data);
        }
    }
};

bindSagas({ LIFE_CYCLE_EVENT_BUS });


export { LIFE_CYCLE_EVENT_BUS, effects };
