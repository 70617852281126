import { runSaga as runReduxSaga, stdChannel } from 'redux-saga';
// import effects from 'redux-saga/effects';
import * as effects from 'redux-saga/effects';
import { SAGA_ACTION } from '@redux-saga/symbols';

let IO;
export const bindSagas = ({ LIFE_CYCLE_EVENT_BUS }) => {


  const channel = stdChannel();
  IO = {
    // this will be used to orchestrate take and put Effects
    channel,
    // this will be used to resolve put Effects
    dispatch(event) {
      // emitter.emit("action", output);
      const output = {
        ...event
      };
      delete output.type;
      delete output[SAGA_ACTION];
      LIFE_CYCLE_EVENT_BUS.trigger(event.type, output);
    },
    // this will be used to resolve select Effects
    getState() {
      return {};
    }
  };

  LIFE_CYCLE_EVENT_BUS.on('*', (output) => {

    channel.put({
      [SAGA_ACTION]: true,
      type:  output.eventNamespace === 'generic'
        ? output.eventName
        : `${output.eventNamespace}.${output.eventName}`,
      ...output
    });
  });

  LIFE_CYCLE_EVENT_BUS.lifeCycle.on('*', (output) => {
    channel.put({
      [SAGA_ACTION]: true,
      type:  output.eventNamespace === 'generic'
        ? output.eventName
        : `${output.eventNamespace}.${output.eventName}`,
      ...output,
    });
  });
};

/**
 * all()
 *
 * returns when all events have completed
 * @param {array} events - array of event names
 * @param fn {function} - callback function
 */
export const all = (events, fn) => {
  bindSaga(function* allSaga() {
    while(true) {
      const results = yield effects.all(events.map(eventName => effects.take(eventName)));
      fn(results);
    }
  })
};


/**
 * allOnce()
 *
 * returns when all events have completed
 * @param {array} events - array of event names
 * @param fn
 * @param fn {function} - callback function
 */
export const allOnce = (events = [], fn) => {
  bindSaga(function* allSaga() {
    const results = yield effects.all(events.map(eventName => effects.take(eventName)));
    fn(results);
  })
};


/**
 * raceOnce()
 *
 * returns the first
 *
 * @param {object} events -
 * @param fn {function} - callback function
 */
export const raceOnce = (events, fn) => {
  bindSaga(function* raceSaga() {
    const obj = Object.keys(events).reduce((acc, cur) => {
      return {
        ...acc,
        [cur]: Array.isArray(events[cur])
          ? effects.all(events[cur].map(evtName => effects.take(evtName)))
          : effects.take(events[cur])
      };
    }, {});
    const results = yield effects.race(obj);
    fn(results);

  })
};


/**
 * sequence()
 *
 * listen for events in a specific order
 * @param {array} events - array of event names
 * @param fn {function} - callback function
 */
export const sequence = (events, fn) => {
  bindSaga(function* sequence() {
    while(true) {
      let results = [];
      for (let i = 0; i < events.length; i++) {
        const event = events[i];
        const response = yield effects.take(event);
        results.push(response);
      }
      fn(results);
    }
  })
};

/**
 * sequenceOnce()
 *
 * listen for events in a specific order
 * @param {array} events - array of event names
 * @param fn {function} - callback function
 */
export const sequenceOnce = (events, fn) => {
  bindSaga(function* sequenceOnce() {
    let results = [];
    for (let i = 0; i < events.length; i++) {
      const event = events[i];
      const response = yield effects.take(event);
      results.push(response);
    }
    fn(results);
  })
};

export const runSaga = (fn) => {
  bindSaga(fn);
};


export const bindSaga = (fn) => {
  if (!IO) return;
  runReduxSaga(
    IO,
    fn,
  );
};


